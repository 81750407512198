<template>
  <ThankYouPage />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ThankYouPage from 'chimera/all/themes/blueflow/page/ThankYou/ThankYouPage'

export default {
  components: {
    ThankYouPage,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Demande complétée',
      headDescription:
        "Entrez votre code postal et comparez gratuitement 6 devis des meilleures entreprises de votre région. ✓ Le meilleur prix pour votre fournisseur de panneaux solaires ✓ Économisez jusqu'à 40% ✓ 100% gratuit!",
      path: '/quotes-requests/demande-completee',
    }
  },
}
</script>
